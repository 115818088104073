.font-selection {
    position: relative;

    .current-size {
        border: 1px solid #caced0;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 41px;

        > p {
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin: 0;
            height: 41px;
            &:hover {
                background-color: #e0dbdb;
            }
        }
        .vert-divider {
            height: 60%;
            width: 1px;
            background-color: black;
        }
        .svg-container {
            padding: 0 5px;
            display: flex;
            align-items: center;
            height: 41px;
            &:hover {
                background-color: #e0dbdb;
            }
        }
    }

    .dropdown {
        display: flex;
        flex-direction: column;
        border: 1px solid #caced0;
        position: absolute;
        background-color: white;
        top: 42px;
        right: 0;
        z-index: 2;
        list-style: none;
        padding: 5px 0;
        width: 50px;
        overflow-y: scroll;
        max-height: 150px;

        > li {
            width: 100%;
            text-align: center;
            cursor: pointer;

            &:hover {
                background-color: #e0dbdb;
            }
        }
    }
}
